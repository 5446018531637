<template>
  <svg
    fill="none"
    height="13"
    viewBox="0 0 13 13"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33594 0.816406L5.78906 1.33594C5.65234 1.47266 5.65234 1.69141 5.78906 1.80078L10.0273 6.03906H0.703125C0.511719 6.03906 0.375 6.20312 0.375 6.36719V7.13281C0.375 7.32422 0.511719 7.46094 0.703125 7.46094H10.0273L5.78906 11.7266C5.65234 11.8359 5.65234 12.0547 5.78906 12.1914L6.33594 12.7109C6.44531 12.8477 6.66406 12.8477 6.80078 12.7109L12.5156 6.99609C12.6523 6.85938 12.6523 6.66797 12.5156 6.53125L6.80078 0.816406C6.66406 0.679688 6.44531 0.679688 6.33594 0.816406Z"
      fill="currentColor"
    />
  </svg>
</template>
